import _ from 'lodash';
import $ from 'jquery';
import confirmation from 'common/components/ConfirmationDialog';
import DisconnectLayerOptionsModal from '../components/disconnect-layer-options-modal';
import I18n from 'common/i18n';
import React from 'react';
import ReactDOM from 'react-dom';

const scope = 'screens.admin.connector.layer_disconnect';

function disconnectSelect(event) {
  event.preventDefault();
  const layerName = _.get(event, 'target.attributes.data-layer-name.nodeValue', 'N/A');
  // event.target.attributes['data-layer-name'].nodeValue; // ['target']['attributes']['data-layer-name']['nodeValue'];
  const layerID = _.get(event, 'target.attributes.data-layer-id.nodeValue', null);
  // event.target.attributes['data-layer-id'].nodeValue; // ['target']['attributes']['data-layer-id']['nodeValue'];
  const disconnectButton = $(event.currentTarget);
  const optionTypes = {WITH_DELETE: 'delete', WITHOUT_DELETE: 'keep'};

  let targetNode = document.querySelector('#admin-connectors-disconnect-element');
  if (!targetNode) {
    targetNode = document.createElement('div');
    targetNode.setAttribute('id', 'admin-connectors-disconnect-element');
    document.body.appendChild(targetNode);
  }

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(targetNode);
  };

  const handleSave = (selected) => {
    if (selected === optionTypes.WITHOUT_DELETE) {
      // make it so the next click will not trigger the selection modal
      disconnectButton.unbind('click');
      // click to actually disconnect the connection
      disconnectButton.trigger('click');
      handleClose();
    } else {
      confirmation(
        <p>{I18n.t('delete_confirm.message', { scope, layer_name: layerName })}</p>,
        'admin-connectors-disconnect-and-delete-confirmation-element',
        {
          agree: { text: I18n.t('delete_confirm.button', { scope }), className: 'btn-error' },
          header: I18n.t('delete_confirm.title', { scope })
        }
      ).then((confirmResolution) => {
        if (confirmResolution.confirmed) {
          // click the hidden disconnect and delete button
          $(`#disconnect_and_delete_${layerID}`).trigger('click');
          handleClose();
        }
      });
    }
  };

  ReactDOM.render(
    <DisconnectLayerOptionsModal
      OptionTypes={optionTypes}
      layerName={layerName}
      onClose={handleClose}
      onSave={handleSave} />,
    targetNode
  );
}

$(document).ready(() => {
  $('.disconnectLayer').on('click', disconnectSelect);
});
