// A option selection dialog that upon saving performs the action which is
// associated with that option. For instance, an option might trigger a
// confirmation modal, and the result of that is returned. Or the action
// associated with an option might simply return a value
import I18n from 'common/i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter} from 'common/components/Modal';

const scope = 'screens.admin.connector.layer_disconnect';

function t(subkey) {
  return I18n.t(`${scope}.${subkey}`);
}

class DisconnectLayerOptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
    this.handleSave = this.handleSave.bind(this);
  }

  onChangeDisconnectOptionType(OptionType) {
    this.setState({ selected: OptionType });
  }

  handleSave() {
    const { onSave } = this.props;
    onSave(this.state.selected);
  }

  renderDisconnectOptionType(OptionType, layerName) {
    const text = I18n.t(`${OptionType}_label`, {scope, layer_name: layerName});
    const body = I18n.t(`${OptionType}_body`, {scope, layer_name: layerName});
    return (
      <div className="disconnect-option-container">
        <div className="left-col disconnect-option-left-col">
          <input
            id={`disconnect-option-${OptionType}`}
            type="radio"
            name="disconnect-option-type-radio"
            checked={this.state.selected === OptionType}
            onChange={() => this.onChangeDisconnectOptionType(OptionType)} />
        </div>
        <div className="right-col disconnect-option-right-col">
          <label htmlFor={`disconnect-option-${OptionType}`}>
            <span className="fake-radiobutton" />
            <h6 dangerouslySetInnerHTML={{ __html: text }} />
          </label>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    );
  }

  render() {
    const { onClose, layerName } = this.props;
    const { WITH_DELETE, WITHOUT_DELETE} = this.props.OptionTypes;

    return (
      <Modal className="disconnect-layer-options-container" onDismiss={onClose}>
        <ModalHeader showCloseButton={false} onDismiss={onClose}>
          <h1>{t('header')}</h1>
          <p className="subheader">{t('sub_header')}</p>
        </ModalHeader>
        <ModalContent>
          {this.renderDisconnectOptionType(WITHOUT_DELETE, layerName)}
          {this.renderDisconnectOptionType(WITH_DELETE, layerName)}
        </ModalContent>
        <ModalFooter>
          <button onClick={onClose} className="btn btn-default confirmation-cancel">
            {t('cancel')}
          </button>
          <button
            onClick={this.handleSave}
            className="btn btn-primary confirmation-accept"
            disabled={this.state.selected == null}>
            {t('save')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

DisconnectLayerOptionsModal.propTypes = {
  layerName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  OptionTypes: PropTypes.object.isRequired
};

export default DisconnectLayerOptionsModal;
